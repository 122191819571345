
import Vue from "vue";
import { Component } from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import code, { Code } from "@/store/code";

@Component({
  components: { Markdown, S404 },
})
export default class CodeDetail extends Vue {
  name = "CodeDetail";

  get code(): Code | undefined {
    const c = this.$i18n.locale === "zh" ? code.zh : code.en;
    return c.find((v) => v.title === this.$route.params.name);
  }
}
