export interface Code {
    title: string
    md?: string | (() => Promise<string | typeof import("*.md")>) // 通常是通过异步require引入一个markdown文件。（利用了markdown-loader包，实际上require markdown文件得到的就是一段html字符串，因此理论上，这里填写markdown字符串也是没有任何问题的）
    href?: string
    abstract?: string
    img?: string
    tag?: { content: string, color: string }
}

function state() {
    const code: Code[] = [
        {
            title: "THU-DeepHypergraph",
            abstract: "A pytorch library for hypergraph learning for medical image, 3D and other hypergraph applications.",
            href: "https://github.com/iMoonLab/THU-DeepHypergraph",
            tag: { content: "TOOLBOX", color: "primary" },
            img: require("../assets/code/dhg.png")
        },
        {
            title: "THU-HyperG",
            abstract: "A python toolbox for hypergraph learning, where several hypergraph generation methods and learning methods on hypergraph as well as some useful utilities are provided.",
            href: "https://github.com/iMoonLab/THU-HyperG",
            tag: { content: "TOOLBOX", color: "primary" }
        },
        {
            title: "DHGNN",
            abstract: `Source code for IJCAI19 paper: "Dynamic Hypergraph Neural Networks"`,
            href: "https://github.com/iMoonLab/DHGNN",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/dhgnn.png")
        },
        {
            title: "MeshNet",
            abstract: `Source code for "MeshNet: Mesh Neural Network for 3D Shape Representation" (AAAI 2019)`,
            href: "https://github.com/iMoonLab/MeshNet",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/meshnet.png")
        },
        {
            title: "PVRNet",
            abstract: `Source code for "PVRNet: Point-View Relation Neural Network for 3D Shape Recognition" (AAAI 2019)"`,
            href: "https://github.com/iMoonLab/PVRNet",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/pvrnet.png")
        },
        {
            title: "HGNN",
            abstract: `Source code for "Hypergraph Neural Networks" (AAAI 2019)`,
            href: "https://github.com/iMoonLab/HGNN",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/hgnn.png")
        },
    ]
    const code_zh: Code[] = [
        {
            title: "THU-DeepHypergraph",
            abstract: "用于医学图像、3D和其他超图应用的超图学习的pytorch库。",
            href: "https://github.com/iMoonLab/THU-DeepHypergraph",
            tag: { content: "TOOLBOX", color: "primary" },
            img: require("../assets/code/dhg.png")
        },
        {
            title: "THU-HyperG",
            abstract: "一个用于超图学习的python工具箱，其中提供了几种超图生成方法和超图学习方法以及一些有用的实用程序。",
            href: "https://github.com/iMoonLab/THU-HyperG",
            tag: { content: "TOOLBOX", color: "primary" }
        },
        {
            title: "DHGNN",
            abstract: `IJCAI19 论文: "Dynamic Hypergraph Neural Networks"的源代码`,
            href: "https://github.com/iMoonLab/DHGNN",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/dhgnn.png")
        },
        {
            title: "MeshNet",
            abstract: `"MeshNet: Mesh Neural Network for 3D Shape Representation" (AAAI 2019)的源代码`,
            href: "https://github.com/iMoonLab/MeshNet",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/meshnet.png")
        },
        {
            title: "PVRNet",
            abstract: `"PVRNet: Point-View Relation Neural Network for 3D Shape Recognition" (AAAI 2019)"的源代码`,
            href: "https://github.com/iMoonLab/PVRNet",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/pvrnet.png")
        },
        {
            title: "HGNN",
            abstract: ` "Hypergraph Neural Networks" (AAAI 2019)的源代码`,
            href: "https://github.com/iMoonLab/HGNN",
            tag: { content: "SOURCE CODE", color: "secondary" },
            img: require("../assets/code/hgnn.png")
        },
    ]
    return { zh: code_zh, en: code }
}

export default state()
