
import Vue from "vue";
import { Component } from "vue-property-decorator";
import code, { Code as CodeType } from "@/store/code";

@Component
export default class Code extends Vue {
  name = "Code";

  get codes(): CodeType[] {
    return this.$i18n.locale === "zh" ? code.zh : code.en;
  }

  href(code: CodeType): string | undefined {
    if (code.md) return `/code/${code.title}`;
    else if (code.href) return code.href;
  }

  get cardHeight(): number {
    if (this.$vuetify.breakpoint.xl) return 450;
    else return 350;
  }
}
